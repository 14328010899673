<template>
  <v-dialog v-model="show" max-width="10cm" persistent>
    <v-card class="pt-5">
      <v-card id="currentTagToPrint" flat>
        <div
          style="
            padding: 10px;
            min-width: 10cm;
            min-height: 10cm;
            max-width: 10cm;
            max-height: 10cm;
            text-align: center;
          "
        >
          <img
            class="image-header"
            :src="getCompanyLogo"
            alt=""
            style="max-width: 120px; margin: auto"
          />
          <barcode v-bind:value="volume['volumeId']">
            {{ errorMessage }}</barcode
          >
          <div>
            {{ currentProject.client }}
          </div>
          <div style="font-size: 10px">
            {{ getAmbient }}
          </div>
          <v-list dense subheader>
            <div
              style="
                font-size: 10px;
                max-height: 5cm;
                display: flex;
                flex-flow: column wrap;
              "
            >
              <div v-for="piece in volume.pieces" :key="piece._id">
                {{ `(${piece.pieceId}) ${piece.name}` }}
              </div>
            </div>
          </v-list>
        </div>
      </v-card>

      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn color="primary" text class="ma-2 white--text" @click="_printTag">
          Imprimir
        </v-btn>
        <v-btn
          color="primary"
          text
          class="ma-2 white--text"
          @click="dismissAction"
        >
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VueBarcode from 'vue-barcode';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    barcode: VueBarcode
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    volume: { type: Object },
    dismissAction: { type: Function }
  },
  data: () => ({
    currentCompany: {},
    currentProject: {},
    errorMessage: 'CÓDIGO DE BARRAS FALHOU'
  }),

  async mounted() {
    await this.getCurrentCompany();
    await this.getCurrentProject();
  },

  computed: {
    ...mapGetters(['getUser', 'getProjects']),
    getCompanyLogo() {
      return this.currentCompany.icon || '/static/light.png';
    },
    getAmbient() {
      return this.volume.pieces.length > 0
        ? this.volume.pieces[0].ambient
        : '-';
    }
  },

  methods: {
    ...mapActions({
      _getCompany: 'getCompany',
      _getProject: 'getProject'
    }),
    _printTag() {
      setTimeout(() => {
        this.$htmlToPaper('currentTagToPrint', {
          name: '_blank',
          specs: ['fullscreen=yes'],
          autoClose: true
        });
      }, 1000);
    },
    async getCurrentCompany() {
      const user = this.getUser;
      if (user && user.company) {
        this.currentCompany = await this._getCompany(user.company);
      }
    },
    async getCurrentProject() {
      if (this.volume) {
        this.currentProject = await this._getProject(this.volume.project);
      }
    }
  }
};
</script>
<style lang="css" scoped>
.v-card__actions {
  padding: 10px 0 0px;
  justify-content: center;
}
</style>
