<template>
  <v-chip :selected="true" label dark :color="_getLabelProps(status).color"
    >{{ _getLabelProps(status).text.toUpperCase()
    }}<v-icon right>{{ _getLabelProps(status).icon }}</v-icon></v-chip
  >
</template>

<script>
import constants from '@/utils/constants';

export default {
  props: {
    status: { type: String }
  },
  data: () => ({}),

  methods: {
    _getLabelProps(status) {
      switch (status) {
        case constants.STATUS_PACKED:
          return { text: 'empacotado', color: 'blue', icon: 'check_box' };
        case constants.STATUS_ON_BOARD:
          return { text: 'embarcado', color: 'orange', icon: 'local_shipping' };
        case constants.STATUS_DELIVERED:
          return { text: 'entregue', color: 'green', icon: 'check_circle' };
        case constants.STATUS_CREATED:
          return {
            text: 'pronto para embarcar',
            color: 'cyan',
            icon: 'check_box'
          };
        case constants.STATUS_ARCHIVED:
          return {
            text: 'Arquivado',
            color: 'black',
            icon: 'archive'
          };
        default:
          return {
            text: 'pronto para empacotar',
            color: 'red',
            icon: 'inventory'
          };
      }
    }
  }
};
</script>
